import axios from 'axios'
import router from '../router'
import { Toast } from 'vant';


const service =axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // api2 的 base_url
  baseURL:'https://kxp.mywhh.com/api',
  headers: { 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryFLft3zdV3J6nOvlM'},
  dataType: "json",
  timeout: 5000 // request timeout
})
service.interceptors.request.use((config)=>{
  return config;
},(error)=>{
  return Promise.reject(error);
})
service.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.fail('服务端异常');
    return Promise.reject(res)
  }
  if (res.data.error != 0) {
    if (res.data.error == 401) {
      localStorage.removeItem('api_token')
      router.push({ path: '/login' })
    }
    Toast.fail(res.data.message);
  }
  return res.data
},error=>{
  if (error.response) {
    if (error.error == 404) {
      console.log(232)
    }else if(error.error == 500){
      console.log(232)
    }
  }
})

export default service