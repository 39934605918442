import axios from 'axios'
import router from '../router'
import { Toast } from 'vant';


const service =axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // api2 的 base_url
  baseURL:'https://kxp.mywhh.com/api',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  dataType: "json",
  timeout: 5000 // request timeout
})
// Vue.config.productionTip = false
// Vue.prototype.$axios = instance
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers['token'] = localStorage.getItem('token') || ''
// axios.defaults.headers.post['Content-Type'] = 'application/json'

service.interceptors.request.use((config)=>{
  if(config.url.indexOf('upload-to-oss')==-1){
    service.defaults.transformRequest = [function(data) {
      if (!data) return data // 没有，直接返回
      return dataToFormData(data)
    }]
  }
  function dataToFormData(oData) {
    const oFormData = new FormData()
    for (const [key, val] of Object.entries(oData)) {
      if (Array.isArray(val)) { // 是
        for (const oneItem of val) oFormData.append(key, oneItem)
      } else {
        oFormData.append(key, val)
      }
    }
    return oFormData
  }
  let api_token=localStorage.getItem('api_token')
  let appid=localStorage.getItem('Appid')
  if(api_token){
    if(config.url.indexOf('create-store-dealer')>-1){
      config.headers['Api-token'] = api_token
    }else{
      delete config.headers['Api-token']
    }
    config.headers['Appid'] = appid
  }
  if(config.url.indexOf('upload-to-oss')!=-1){
    config.headers['content-type'] = 'multipart/form-data; boundary=----WebKitFormBoundaryFLft3zdV3J6nOvlM'
  }
  return config;
},(error)=>{
  return Promise.reject(error);
})
service.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.fail('服务端异常');
    return Promise.reject(res)
  }
  if (res.data.error != 0) {
    if (res.data.error == 401) {
      localStorage.removeItem('api_token')
      router.push({ path: '/login' })
    }
    Toast.fail(res.data.message);
  }
  return res.data
},error=>{
  if (error.response) {
    if (error.error == 404) {
      console.log(232)
    }else if(error.error == 500){
      console.log(232)
    }
  }
})

export default service