import axios from '../utils/axios'
import axios1 from '../utils/axios1'

export function brand(data) {
  return axios({
    url: '/supplier/dealer/html-five/brand-list',
    method: 'get',
    params:data
  })
}
export function login(data) {
  return axios({
    url: '/supplier/dealer/html-five/html-five-login',
    method: 'post',
    data
  })
}
export function merchant(data) {
  return axios({
    url: '/supplier/dealer/html-five/merchant-list',
    method: 'get',
    params:data
  })
}
export function update(data) {
  return axios1({
    url: '/upload-to-oss',
    method: 'post',
    data:data,
  })
}
export function account(data) {
  return axios({
    url: '/fmcg/merchant-order-new/dealer-register-account',
    method: 'get',
    params:data
  })
}
//添加门店
export function storeDealer(data) {
  return axios({
    url: '/supplier/dealer/html-five/create-store-dealer',
    method: 'post',
    data:data,
  })
}
